<template>
  <b-autocomplete
    ref="autocomplete"
    v-model="query"
    :data="filteredDataObj"
    field="name"
    icon="search"
    placeholder="Select a charity"
    :loading="loading"
    @select="onSelect"
    open-on-focus
    clearable
  >
    <template #empty>No results for {{ query }}</template>
  </b-autocomplete>
</template>

<script>
import {AuthError, NetworkError} from "@/errors"

export default {
  props: {
    value: {
      type: String
    }
  },
  data() {
    return {
      charities: [],
      error: null,
      loading: false,
      query: "",
      selected: null
    }
  },
  computed: {
    filteredDataObj() {
      return this.charities.filter(option => {
        if (option.enabled) {
          return (
            option.name
              .toString()
              .toLowerCase()
              .indexOf(this.query.toLowerCase()) >= 0
          )
        }
      })
    }
  },
  methods: {
    updateCharities() {
      if (this.loading) {
        return
      }

      this.error = null
      this.loading = true
      this.$store.dispatch("getCharities")
        .then(resp => {
          this.charities = resp
        })
        .catch(error => {
          if (error instanceof AuthError || error instanceof NetworkError) {
            throw error
          } else {
            this.error = error
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    onSelect(option) {
      this.selected = option
      this.$emit("input", this.selected)
    }
  },
  mounted() {
    this.updateCharities()
  }
}
</script>
