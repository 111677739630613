<template>
  <div
    class="card"
    style="cursor: pointer;"
  >
    <header class="card-header">
      <button class="card-header-icon">
        <span class="icon">
          <i class="fas fa-hand-holding-heart"></i>
        </span>
      </button>
      <p class="card-header-title">
        {{ charity.name }}
      </p>
    </header>
    <div class="card-content">
      <div class="content">
        {{ charity.about }}
      </div>
      <div
        v-if="charity.locality && charity.administrative_area_level_1"
        class="content"
      >
        <span class="icon">
          <i class="fas fa-map-marker-alt"></i>
        </span>
        {{ charity.locality }}, {{ charity.administrative_area_level_1 }}
      </div>
      <div
        v-if="charity.website"
        class="content"
      >
        <span class="icon">
          <i class="fas fa-globe"></i>
        </span>
        <a
          :href="charity.website"
          target="_blank"
        >
          {{ charity.website }}
        </a>
      </div>
      <div
        v-if="charity.phone"
        class="content"
      >
        <span class="icon">
          <i class="fas fa-phone"></i>
        </span>
        <a
          :href="phoneURL"
        >
          {{ charity.phone }}
        </a>
      </div>
      <Notification
        v-if="success"
        type="success"
      >
        {{ success }}
      </Notification>
      <Notification
        v-if="error"
        type="danger"
      >
        {{ error }}
      </Notification>
    </div>
    <footer class="card-footer">
      <p
        v-if="currentUser.balance > 0"
        class="card-footer-item"
      >
        <DollarAmountButton
          label="Donate"
          v-model.trim="$v.donationDollars.$model"
          :validation="$v.donationDollars"
          :submitting="submitting"
          @confirm="donate"
        >
          <p class="block">
            Are you sure you want to donate ${{ donationDollars }} to <strong>{{ charity.name }}</strong>?
          </p>
          <p class="block">
            <em>This amount will be immediately deducted from your balance.</em>
          </p>
        </DollarAmountButton>
      </p>
      <p
        v-else
        class="card-footer-item"
      >
        <span>Donate when you earn a balance</span>
      </p>
    </footer>
  </div>
</template>

<script>
import { AuthError, NetworkError } from "@/errors"
import Notification from "@/components/Notification.vue"
import DollarAmountButton from "@/components/DollarAmountButton.vue"
import { helpers, integer, minValue, required } from 'vuelidate/lib/validators'

const maxValueOf = (param) => helpers.withParams(
  { type: "maxValueOf", value: param },
  (value, vm) => !helpers.req(value) || value <= vm[param]
)

export default {
  props: {
    charity: {
      type: Object,
      required: true
    }
  },
  components: {
    DollarAmountButton,
    Notification
  },
  data() {
    return {
      success: null,
      error: null,
      submitting: false,
      donationAmount: 0
    }
  },
  validations: {
    donationDollars: {
      required,
      integer,
      minValue: minValue(1),
      maxValue: maxValueOf("balanceDollars")
    }
  },
  computed: {
    currentUser: function() {
      return this.$store.getters.currentUser
    },
    balanceDollars: function() {
      return Math.round(this.currentUser.balance / 100)
    },
    donationDollars: {
      get: function() {
        return Math.round(this.donationAmount / 100)
      },
      set: function(newValue) {
        this.donationAmount = Math.round(newValue) * 100
      }
    },
    canDonate: function() {
      return (this.donationAmount > 0 && this.donationAmount <= this.currentUser.balance) ? true : false
    },
    phoneURL: function() {
      return `tel:+1${this.charity.phone.replaceAll(/\s/g, "")}`
    }
  },
  methods: {
    donate() {
      if (!this.canDonate) {
        return
      }

      this.success = null
      this.error = null
      this.submitting = true

      const params = {
        user_slug: this.currentUser.slug,
        charity_slug: this.charity.slug,
        amount: this.donationAmount
      }
      this.$store.dispatch("donateToCharity", params)
        .then(resp => {
          console.log(resp)
          this.success = resp.message
          this.donationAmount = 0
        })
        .catch(error => {
          if (error instanceof AuthError || error instanceof NetworkError) {
            throw error
          } else {
            this.error = error.message
          }
        })
        .finally(() => {
          this.submitting = false
        })
    }
  }
}
</script>

<style scoped>
.card-header {
  background-color: hsl(204, 86%, 53%);
}
.card-header-icon, .card-header-title {
  color: hsl(0, 0%, 98%);
}
</style>
