<template>
  <div class="field has-addons">
    <div
      :class="controlIconsClass"
    >
      <input
        :class="inputClass"
        type="number"
        :value="value"
        :disabled="disabled || submitting"
        @input="$emit('input', $event.target.value)"
      />
      <span class="icon is-small is-left">
        <i class="fas fa-dollar-sign"></i>
      </span>
      <span
        v-if="error"
        class="icon is-small is-right"
      >
        <i class="fas fa-exclamation-triangle"></i>
      </span>
      
    </div>
    <div class="control">
      <ConfirmButton
        :buttonLabel="label"
        buttonType="info"
        :disabled="!canSubmit"
        :submitting="submitting"
        @confirm="$emit('confirm', $event)"
        @deny="$emit('deny', $event)"
      >
        <slot></slot>
      </ConfirmButton>
    </div>
  </div>
</template>

<script>
import ConfirmButton from "@/components/ConfirmButton.vue"

export default {
  props: {
    value: {
      type: Number
    },
    label: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    submitting: {
      type: Boolean,
      default: false
    },
    validation: {
      type: Object
    }
  },
  components: {
    ConfirmButton
  },
  computed: {
    error: function() {
      return (this.validation && this.validation.$error) ? this.validation.$error : null
    },
    inputClass: function() {
      return (this.error) ? "input is-full-width is-danger" : "input is-full-width"
    },
    controlIconsClass: function() {
      return (this.error) ? "control has-icons-left has-icons-right" : "control has-icons-left"
    },
    canSubmit: function() {
      return (this.error || this.disabled) ? false : true
    }
  },
  mounted() {
    if (this.validation) {
      this.validation.$touch() 
    }
  }
}
</script>
