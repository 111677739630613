<template>
  <SettingsPage>
    <Notification
      v-if="error"
      type="danger"
    >
      {{ error }}
    </Notification>

    <div class="block">
      <BaseCharitySearch
        @input="onCharitySelect"
      />
    </div>

    <div class="block">
      <CharityCard
        v-if="charity"
        :charity="charity"
      />
      <div
        v-else
        class="box"
      >
        <h2 class="has-text-centered">
          <span class="icon is-large">
            <i class="fas fa-2x fa-hand-holding-heart"></i>
          </span>
        </h2>
        <h3 class="title has-text-centered">
          Giving Back
        </h3>
        <p class="block">
          Consider donating a portion of your balance to a good cause!
        </p>
        <p class="block">
          You can donate any time you have a balance. We'll immediately apply the donation to the charity of your choice and pay out to the charity every quarter.
        </p>
      </div>
    </div>
  </SettingsPage>
</template>

<script>
//import {AuthError, NetworkError} from "@/errors/index.js"
import SettingsPage from "@/components/SettingsPage.vue"
import Notification from "@/components/Notification.vue"
import BaseCharitySearch from "@/components/form/BaseCharitySearch.vue"
import CharityCard from "@/components/CharityCard.vue"

export default {
  components: {
    SettingsPage,
    Notification,
    BaseCharitySearch,
    CharityCard
  },
  data() {
    return {
      loading: false,
      error: null,
      submitting: false,
      charity: null
    }
  },
  methods: {
    onCharitySelect(charity) {
      this.charity = charity
    },
    prepareDonation(charity) {
      console.log(charity)
    }
  }
}
</script>
